import deviceUsageProfile from "@/apis/deviceUsageProfile";
import useErrorDialog from "@/cleanup/widgets/dialog/useErrorDialog";
import SntSaveIcon from "@/components/Icons/SntSaveIcon";
import InternalTabs from "@/components/InternalTabs/InternalTabs";
import SntHideLongText from "@/components/ReactBootstrap/SntHideLongText";
import SntBrowserTabTitle from "@/components/SntBrowserTabTitle/SntBrowserTabTitle";
import SntDialog from "@/components/SntDialog/SntDialog";
import BackHistory from "@/containers/BackHistory/BackHistory";
import queryString from "query-string";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useRoutes } from "../../components/SntTab/useRoutes";
import ChangeModeHeaderIcon from "../../containers/PageHeader/ChangeModeHeaderIcon";
import DeviceProfileAuditLog from "./DeviceProfileAuditLog";
import DeviceProfileConfiguration from "./DeviceProfileConfiguration";
import DeviceProfileGeneralInfo from "./DeviceProfileGeneralInfo";
import DeviceUsageProfileTabIds from "./DeviceUsageProfileTabIds";

const DeviceUsageProfile = ({ reload }) => {
  const language = useSelector((state) => state.language);
  const history = useHistory();
  const location = useLocation();
  let { id, type } = queryString.parse(location.search);
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap } = loginInfo;

  const [profile, setProfile] = useState();

  const profileToUpdate = useRef({
    name: "",
    referenceProduct: null,
    supportedProducts: [],
    excludedProducts: [],
    accessibleByOrganizations: [],
    accessibleByPartners: [],
    description: "",
    deviceFwParameterSettings: [],
    profileServiceUpdates: [],
    numericVariables: [],
    categories: [],
    excludedRegexProducts: "",
    excludedProductsResult: [],
  });

  const [showConfirmVisibleToAll, setShowConfirmVisibleToAll] = useState(false);

  const [isShowUnChangeConfirm, setShowUnChangeConfirm] = useState(false);

  const route = {
    route: "device_usage_profile_manage?",
    tabActive: DeviceUsageProfileTabIds.general_info,
  };

  const { routeParams } = useRoutes(route);

  useEffect(() => {
    deviceUsageProfile.getById(id).then(({ data }) => {
      let formData = {};

      formData.accessibleByOrganizations = data.accessibleByOrganizations.map(
        (org) => {
          return { value: org.id, label: org.name };
        }
      );
      formData.accessibleByPartners = data.accessibleByPartners.map(
        (partner) => {
          return { value: partner.id, label: partner.name };
        }
      );
      setProfile({ ...data, ...formData });
      profileToUpdate.current = { ...data, ...formData };
    });
  }, [id, reload]);

  const allTabs = useMemo(() => {
    return [
      {
        id: DeviceUsageProfileTabIds.general_info,
        label: language.general_info_key,
        render: true,
        component: () => (
          <DeviceProfileGeneralInfo
            profile={profile}
            profileToUpdate={profileToUpdate}
            onChange={() => {
              checkAndSave();
            }}
            setShowUnChangeConfirm={setShowUnChangeConfirm}
            type={type}
          />
        ),
      },
      {
        id: DeviceUsageProfileTabIds.configuration,
        label: language.config_section_name_key,
        render: true,
        component: () => (
          <DeviceProfileConfiguration
            profile={profile}
            profileToUpdate={profileToUpdate}
            onChange={() => {
              checkAndSave();
            }}
            setShowUnChangeConfirm={setShowUnChangeConfirm}
            type={type}
          />
        ),
      },
      {
        id: DeviceUsageProfileTabIds.audit_log,
        label: language.feature_audit_log_name,
        render: type !== "add",
        component: () => <DeviceProfileAuditLog profile={profile} />,
      },
    ];
    // eslint-disable-next-line
  }, [profile, type]);

  const checkAndSave = () => {
    if (profileToUpdate.current.visibleToAll) {
      setShowConfirmVisibleToAll(true);
    } else {
      onSave();
    }
  };

  const convertDataToServer = (data) => {
    let dataToServer = { ...data };

    dataToServer.referenceProduct = data.referenceProduct?.key || "";
    delete dataToServer.supportedProducts;
    dataToServer.excludedProducts = data.excludedProducts.filter.items?.map(
      (obj) => obj.key
    );

    dataToServer.accessibleByOrganizations = data.accessibleByOrganizations.map(
      (org) => {
        return { id: org.value, name: org.label };
      }
    );

    dataToServer.accessibleByPartners = data.accessibleByPartners.map(
      (partner) => {
        return { id: partner.value, name: partner.label };
      }
    );

    if (dataToServer.visibleToAll) {
      dataToServer.accessibleByOrganizations = [];
      dataToServer.accessibleByPartners = [];
    }

    let serviceConfig = [...dataToServer.profileServiceUpdates];
    serviceConfig = serviceConfig.map((s) => {
      return { type: s.type, active: s.active, configuration: s.configuration };
    });
    dataToServer.profileServiceUpdates = serviceConfig;
    return dataToServer;
  };

  function onSave() {
    let dataToServer = convertDataToServer(profileToUpdate.current);
    if (type === "edit") {
      processUpdate(dataToServer);
    } else {
      createUsageProfile(dataToServer);
    }
  }

  function processUpdate(dataToServer) {
    deviceUsageProfile
      .updateUsageProfile(id, dataToServer)
      .then(({ data }) => {
        setTimeout(() => {
          history.push("/device_usage_profile");
        }, 500);
      })
      .catch((error) => {
        handleOpen(error.response.data.message);
      });
  }

  function createUsageProfile(dataToServer) {
    deviceUsageProfile
      .createUsageProfile(dataToServer)
      .then(({ data }) => {
        setTimeout(() => {
          history.push("/device_usage_profile");
        }, 500);
      })
      .catch((error) => {
        handleOpen(error.response.data.message);
      });
  }

  function onConfirmYes() {
    setShowUnChangeConfirm(false);
    onSave();
  }

  function onConfirmNo() {
    setShowUnChangeConfirm(false);
    setTimeout(() => {
      history.push("/device_usage_profile");
    }, 500);
  }

  function onConfirmVisibleToAllYes() {
    setShowConfirmVisibleToAll(false);
    onSave();
  }

  function onConfirmVisibleToAllNo() {
    setShowConfirmVisibleToAll(false);
  }

  const { handleOpen, ErrorDialog } = useErrorDialog({
    language: language,
    title: language.error_key,
  });

  return (
    <>
      <ErrorDialog />
      <SntDialog
        isShow={showConfirmVisibleToAll}
        onSave={() => onConfirmVisibleToAllYes()}
        onClose={() => onConfirmVisibleToAllNo()}
        saveTxt={language.yes_key}
        closeTxt={language.no_key}
        title={language.confirm_key}
      >
        {() => language.usage_profile_confirm_save_visible_to_all}
      </SntDialog>

      <SntDialog
        isShow={isShowUnChangeConfirm}
        onSave={() => onConfirmYes()}
        onClose={() => onConfirmNo()}
        saveTxt={language.yes_key}
        closeTxt={language.no_key}
        title={language.v42_device_profile_key}
      >
        {() => language.you_have_unsaved_changes_key}
      </SntDialog>

      <div>
        <>
          <SntBrowserTabTitle title={profile?.name || language.v42_device_profile_key} />

          <div className="d-flex justify-content-between heading">
            <div className="d-flex align-items-center w-50">
              <BackHistory />
              <h1 className="page-header">
                <SntHideLongText>
                  {profile?.name || language.v42_device_profile_key}
                </SntHideLongText>
              </h1>
              {type !== "add" && permissionsMap["USAGE_PROFILE_MANAGE"] ? (
                <ChangeModeHeaderIcon />
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-end align-items-center w-50">
              {routeParams["tabActive"] !==
                DeviceUsageProfileTabIds.audit_log &&
                type !== "view" && (
                  <>
                    <Button
                      variant="sensolus"
                      className={"d-flex align-items-center"}
                      onClick={() => checkAndSave()}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <SntSaveIcon />
                        <span
                          title={language.save_key}
                          className={"ms-1 d-none d-lg-inline-block"}
                        >
                          {language.save_key}
                        </span>
                      </div>
                    </Button>
                  </>
                )}
            </div>
          </div>
        </>

        <InternalTabs
          allTabs={allTabs}
          tabGroupInfo={{
            route: route.route,
            tabActive: routeParams.tabActive,
          }}
        />
      </div>
    </>
  );
};

export default DeviceUsageProfile;
